import { Text } from "alisson-application";
import { Container as Button } from "@/presentation/components/Button";
import { useModal } from "@/main/contexts/modal";
import { useDetailAction } from "@/main/hooks/detailAction";

import imgCard from "@/presentation/assets/icone/card-check.svg";
import imgCardPlus from "@/presentation/assets/icone/card-plus-roxo.svg";
import ShortingIcon from "@/presentation/assets/icone/sortingIcon.svg";
import imgAcoesDetalhes from "@/presentation/assets/icone/acoes-detalhes.svg";
import imgEditar from "@/presentation/assets/icone/editar.svg";
import { Container } from "./styles";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Loader } from "../Loader";

interface TotalVoucherRescuedProsp {
    rewardedItems: number;
    maxRewards: number;
    hasGroups: boolean;
}

export function TotalVoucherRescued(props: TotalVoucherRescuedProsp) {
    const { rewardedItems, maxRewards } = props;
    const [value, setValue] = useState<number | null>(null);
    const { handleOpen } = useModal();
    const [disabled, setDisabled] = useState<boolean>(true);
    const { custumize, stateHistory } = useDetailAction();

    async function handlechange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = parseInt(e.target.value.replace(/[^\d]+/g, ""));
        if (!value) {
            setValue(null);
            return;
        }

        setDisabled(true);
        e.target.blur();
        await custumize.actionCustumize({
            id: stateHistory.id,
            max_rewards: value,
        });
        toast.success("Quantidade alterada com sucesso");
        setValue(value);

        setDisabled(false);
    }

    useEffect(() => {
        if (maxRewards) {
            setValue(maxRewards);
        }
    }, [props.maxRewards]);

    return (
        <Container>
            <div className="card-voucher">
                <div className="container-img">
                    <img src={imgCard} alt="" style={{ marginTop: "9px" }} />
                </div>
                <Text
                    as="p"
                    mt="14px"
                    fontSize="xs"
                    mb="6px"
                    color="secondarydark"
                >
                    Total de vouchers / resgatados
                </Text>
                <Text as="h5" fontSize="mdl" mt="6px">
                    {rewardedItems}
                </Text>
            </div>

            <div className={`card-voucher ${disabled ? "disabled" : ""}`}>
                <input
                    type="tel"
                    maxLength={2}
                    pattern="\d*"
                    value={
                        Number.isInteger(value) && value !== null ? value : ""
                    }
                    onChange={handlechange}
                    disabled={disabled}
                    placeholder="0"
                />
                <Text as="p" color="secondarydark" mt="11px">
                    Total de resgates permitidos
                </Text>

                <button
                    onClick={() => {
                        setDisabled(!disabled);
                        if (disabled) {
                            toast.info(
                                "Você pode alterar a quantidade de resgates permitidos"
                            );
                        }
                    }}
                >
                    <img src={imgEditar} alt="" style={{ marginTop: "2px" }} />
                    <Text as="h5" mt="2px">
                        Editar
                    </Text>
                    <div className="loader">
                        <Loader width={10} height={10} />
                    </div>
                </button>
            </div>

            <div className="container-action-voucher">
                <div className="container-button">
                    <div className="container-img">
                        <img src={imgCardPlus} alt="" />
                    </div>
                    <Button
                        mt="20px"
                        onClick={() => handleOpen("selectVouchers")}
                    >
                        Adicionar Vouchers
                    </Button>
                </div>
            </div>

            <div className="container-action-voucher">
                <div className="container-button">
                    <div className="container-img">
                        <img src={imgAcoesDetalhes} alt="" />
                    </div>

                    <Button onClick={() => handleOpen("createGroup")} mt="20px">
                        Criar novo grupo
                    </Button>
                </div>
            </div>

            <div className="container-action-voucher">
                <div className="container-button">
                    <div className="container-img">
                        <img src={ShortingIcon} alt="" />
                    </div>
                    <Button
                        mt="20px"
                        onClick={() => handleOpen("sortingVouchers")}
                    >
                        Reordenar Vouchers
                    </Button>
                </div>
            </div>
        </Container>
    );
}
