import { Mixin } from "ts-mixer";
import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";
import { RemoteActionUserload } from "@/data/useCases/actionUserload/remoteActionUserload";
import { RemoteDetailActionUser } from "@/data/useCases/detailActionUser/detailActionUser";
import { RemoteDetailActionTemplate } from "@/data/useCases/detailActionTemplate/remotedetailActionTemplate";
import { RemoteActionLoadsPeddingList } from "@/data/useCases/actionLoadsPeddingList/remoteActionLoadsPeddingList";
import { RemoteActionApproveAndRepprove } from "@/data/useCases/actionAproveAndReprove/remoteActionAproveAndReprove";
import { RemoteActionUserReset } from "@/data/useCases/actionUserReset/remoteActionUserReset ";
import { RemoteActionUserLinkVoucher } from "@/data/useCases/actionUserLinkVoucher/remoteActionUserLinkVoucher";

const axios = new AxiosHttpClient();

export class User extends Mixin(
    RemoteActionUserload,
    RemoteDetailActionUser,
    RemoteDetailActionTemplate,
    RemoteActionLoadsPeddingList,
    RemoteActionApproveAndRepprove,
    RemoteActionUserReset,
    RemoteActionUserLinkVoucher
) {
    constructor() {
        super(axios);
    }
}
