import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { UnexpectedError } from "@/domain/errors";
import { AccessDeniedError } from "@/domain/errors/accessDeniedError";
import { ActionUserLinkVoucherModel } from "@/domain/models";
import { ActionUserLinkVoucher } from "@/domain/useCases/actionUserLinkVoucher";

export class RemoteActionUserLinkVoucher implements ActionUserLinkVoucher {
    constructor(
        readonly httpGetClient: HttpClient,
        readonly urlNewReport: string = "action/persons/link-rewards"
    ) {}

    async linkRewards(id: number): Promise<ActionUserLinkVoucherModel> {
        const response = await this.httpGetClient.request({
            url: `${this.urlNewReport}/${id}`,
            method: "get",
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                if (response.body) {
                    return response.body;
                }
                return {} as ActionUserLinkVoucherModel;
            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            case HttpStatusCode.noContent:
                return {} as ActionUserLinkVoucherModel;
            default:
                throw new UnexpectedError();
        }
    }
}
