import { useState } from "react";
import { Text } from "alisson-application";
import { v4 as uuidv4 } from "uuid";
import { useModal } from "@/main/contexts/modal";
// import Select, { options } from "@/presentation/components/Select";
import { options } from "@/presentation/components/Select";

import { Container, ContainerDimmed } from "./styles";
import imgEditar from "@/presentation/assets/icone/editar.svg";
import close from "@/presentation/assets/icone/close.svg";
import { When } from "@/presentation/components/when";

interface BoxVoucherModalProps {
    img: string;
    title: string;
    fileName: string;
    width: number;
    height: number;
    onChance: (file: File) => void;
    onSelected?: (option: options) => void;
    arrayOptions?: options[];
    optionSelected?: string | null;
    dimmed?: boolean;
    removeImg?: any;
}

export function BoxVoucherModal(props: BoxVoucherModalProps) {
    const {
        img,
        title,
        fileName,
        onChance,
        arrayOptions,
        // optionSelected,
        // onSelected,
        dimmed,
        removeImg,
    } = props;
    const [file, setFile] = useState<File | null>(null);
    const labelId = "file-" + uuidv4();

    const { setState, state } = useModal();

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const currentFile = e.target.files?.[0];

        if (currentFile) {
            const img = new Image();
            img.src = URL.createObjectURL(currentFile);
            img.onload = () => {
                const objFile = {} as any;
                objFile[fileName] = currentFile;

                if (objFile) {
                    onChance(objFile as File);
                    setFile(currentFile);
                }
            };
        }
    }

    // const hasObtainMethod = arrayOptions?.find(
    //     (method) => method.value === optionSelected
    // );

    const SelectedContainer = dimmed ? ContainerDimmed : Container;

    return (
        <SelectedContainer>
            <When expr={arrayOptions?.length ? true : false}>
                <div className="container-field-box">
                    <Text as="h5" mb="5px" fontSize="xss" textAlign="center">
                        Template Carrossel <br></br>e/ou <br></br> WhatsApp
                    </Text>
                </div>
            </When>

            <div className={`container-img`}>
                <img
                    src={file?.name ? URL.createObjectURL(file) : img}
                    alt=""
                />
            </div>

            <img
                className="removeImagem"
                src={close}
                alt=""
                title="Excluir imagem"
                onClick={async () => {
                    setState({
                        ...state,
                        alert: {
                            active: true,
                            onclick: () => {
                                removeImg();
                            },
                            text: "A imagem será excluída, deseja continuar?",
                        },
                    });
                }}
            />

            <label htmlFor={labelId} className="container-text">
                {arrayOptions?.length ? null : (
                    <Text as="p" mb="8px" color="secondarydark">
                        {title}
                    </Text>
                )}

                <Text as="h5">
                    <img src={imgEditar} alt="" />
                    Editar
                </Text>
            </label>
            <input
                type="file"
                name={labelId}
                id={labelId}
                accept="image/*"
                data-testid={`label-file-${fileName}`}
                onChange={handleChange}
            />
        </SelectedContainer>
    );
}
