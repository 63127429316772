import { Box, Text } from "alisson-application";
import { Container, EscolhaEnvio } from "./styles";
import { CountDownMemo } from "@/presentation/components/Countdown";
import { useModal } from "@/main/contexts/modal";
import { useState, useEffect } from "react";
import imgFechar from "@/presentation/assets/icone/close.svg";
import imgValidate from "@/presentation/assets/icone/validae-login.svg";
import imgQuestao from "@/presentation/assets/icone/questao.svg";
import { Container as Button } from "@/presentation/components/Button";
import { useAuthentication } from "@/main/hooks/authentication";
import { FormWhats } from "./formWhats";
import { FormSms } from "./formSms";

export function SmsValidation() {
    const { handleClose } = useModal();
    const [timer, setTimer] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState(true);
    const [value, setValue] = useState<string>("");
    const { smsValidate, sendSmsAgain, stateMe, sendWhatsapp, account } =
        useAuthentication();
    const phone = stateMe?.phone;

    const [channelSms, setChannelSms] = useState<boolean>(false);
    const [channelWhatsapp, setChannelWhatsapp] = useState<boolean>(false);
    const [channelEmail, setChannelEmail] = useState<boolean>(false);
    const [channelNotification, setChannelNotification] = useState<string>("");

    async function valdiate() {
        setLoading(true);
        const response: any = await smsValidate(value);

        if (response?.valid === false) {
            setError(true);
            setTimer(true);
            setLoading(false);
            return;
        }

        if (response.status !== 200) {
            setError(true);
            setTimer(true);
            setLoading(false);
            return;
        }
    }

    function handleChange() {
        const d = document.getElementById("container-envio");
        if (d?.classList.contains("active")) {
            d?.classList.remove("active");
        } else {
            d?.classList.add("active");
        }
    }

    useEffect(() => {
        account?.notification_channel.map((n) => {
            if (n.channel === "SMS") {
                setChannelSms(n.available);
            } else if (n.channel === "WHATSAPP") {
                if (n.available) {
                    setChannelWhatsapp(n.available);
                }
            } else {
                if (n.available) {
                    setChannelEmail(n.available);
                }
            }
        });

        if (channelSms && !channelWhatsapp && !channelEmail) {
            setChannelNotification("por SMS");
        } else if (!channelSms && channelWhatsapp && !channelEmail) {
            setChannelNotification("para o seu Whatsapp");
        } else if (!channelSms && !channelWhatsapp && channelEmail) {
            setChannelNotification("para o seu E-mail");
        } else {
            setChannelNotification("para o seu celular");
        }
    });

    return (
        <Container>
            <button
                className="close"
                onClick={() => handleClose("smsValidation")}
            >
                <img src={imgFechar} alt="" />
            </button>
            <div className="text-img">
                {/* <Box mb="18px">
                    <img src={imgValidate} alt="" />
                </Box> */}
                <Text mb="20px" as="h5" fontSize="mdl">
                    CÓDIGO DE VERIFICAÇÃO
                </Text>
                <Text as="p" mb="40px">
                    <strong>Preencha com o código de verificação</strong>
                    <br></br> enviado {channelNotification}.
                </Text>
                <div className="container-input">
                    <input
                        type="tel"
                        maxLength={4}
                        pattern="\d*"
                        onChange={(e) => {
                            setValue(e.target.value);
                            setError(false);
                        }}
                    />
                </div>

                {error && <Text as="span">Código inválido</Text>}

                {timer ? (
                    <CountDownMemo
                        setDisabled={setDisabled}
                        setTimer={setTimer}
                    />
                ) : null}

                <Box width="100%" mt="16px" mb="24px">
                    <Button
                        className="btn"
                        onClick={valdiate}
                        isLoading={loading}
                    >
                        Acessar
                    </Button>
                </Box>
            </div>

            <EscolhaEnvio>
                <div
                    className={`container-btn ${disabled ? "disabled" : ""} `}
                    onClick={handleChange}
                >
                    <button>
                        <div className="container-img">
                            <img src={imgQuestao} alt="" />
                        </div>
                        <div className="container-text">
                            <h3>Clique aqui</h3>
                            <p>Se não receber seu código</p>
                        </div>
                    </button>
                </div>
                <div className="container-envio" id="container-envio">
                    {channelWhatsapp && (
                        <div className="container-item">
                            <FormWhats
                                setDisabled={setDisabled}
                                setTimer={setTimer}
                                sendWhatsapp={sendWhatsapp}
                                fecha={handleChange}
                                phone={phone}
                            />
                        </div>
                    )}
                    <div className="container-item">
                        {channelSms && (
                            <FormSms
                                setDisabled={setDisabled}
                                setTimer={setTimer}
                                sendSmsAgain={sendSmsAgain}
                                fecha={handleChange}
                                phone={phone}
                            />
                        )}
                    </div>
                </div>
            </EscolhaEnvio>
        </Container>
    );
}
