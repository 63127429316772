import styled from "styled-components";

export const Container = styled.div`
    width: 600px;
    background: ${(props) => props.theme.colors.white};
    border-radius: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 0;
    .container-header {
        margin-bottom: 37px;
        h1 {
            display: flex;
            align-items: center;
            img {
                display: block;
                margin-right: 12px;
            }
        }
    }

    .link-voucher {
        width: 535px;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        padding: 0 25px;
        background-color: #f5f5f5;
        margin-bottom: 10px;
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0%;
        }
    }

    .container-btns {
        margin-top: 37px;
        button {
            width: 328px;
        }
    }

    button {
        &.btn-close {
            position: absolute;
            top: 30px;
            right: 30px;
        }
    }
`;
