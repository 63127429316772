import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";

export class RemoteDeleteUser {
    constructor(protected readonly httpClient: AxiosHttpClient) {}

    async deleteUser(params: { id: number, origin: string }): Promise<void> {
        const response = await this.httpClient.request({
            url: `users/${params.id}/${params.origin}`,
            method: 'delete',
        });

        if (response.statusCode !== 200) {
            throw new Error('Erro ao deletar o usuário');
        }
    }
}
