import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    min-height: 150px;
    .link-voucher {
        cursor: pointer;
        span {
            font-family: Lato;
            font-weight: 700;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0%;
            color: #6f3e98;
            display: flex;
            gap: 6px;
            img {
                width: 15px;
            }
        }
    }
`;
