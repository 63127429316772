import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { UserADMModel, Pagination } from "@/domain/models";
import { ListUsers } from "@/domain/useCases/listADMUser";

function isUserResponse(
    obj: any
): obj is { data: UserADMModel[]; current_page: number; last_page: number; per_page: number; total: number } {
    return (
        typeof obj === "object" &&
        "data" in obj &&
        Array.isArray(obj.data) &&
        "current_page" in obj &&
        "last_page" in obj &&
        "per_page" in obj &&
        "total" in obj
    );
}

export class RemoteListUsers implements ListUsers {
    private data: UserADMModel[] = [];
    private pagination: Pagination | null = null;

    constructor(
        private readonly httpGetClient: HttpClient<UserADMModel>,
        private readonly url: string = "users/users-and-invites"
    ) {}

    async loadAll(page?: number): Promise<UserADMModel[]> {
    
        const response = await this.httpGetClient.request({
            url: `${this.url}${page ? "?page=" + page : ""}`,
            method: "get",
        });
        
        const remoteUsers = response.body;
    
        if (isUserResponse(remoteUsers)) {
            const usersData = remoteUsers as unknown as {
                data: UserADMModel[];
                current_page: number;
                last_page: number;
                per_page: number;
                total: number;
            };
    
            this.pagination = {
                currentPage: usersData.current_page,
                lastPage: usersData.last_page,
                perPage: usersData.per_page,
                total: usersData.total, 
                totalPages: usersData.last_page,
            };
            
    
            this.data = this.format(usersData.data);
            console.log("Usuários formatados:", this.data);
            return this.data;
        }
        
        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return [];
            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            case HttpStatusCode.noContent:
                return [];
            default:
                throw new UnexpectedError();
        }
    }

    private format(users: UserADMModel[] = []): UserADMModel[] {
    
        const formatted = users.map((user) => ({
            ...user,
            formattedDate: user.created_at
                ? new Date(user.created_at).toLocaleDateString()
                : null,
        }));
    
        return formatted;
    }
    
    private orderByName(users: UserADMModel[]): UserADMModel[] {
        return users.sort((a, b) => a.name.localeCompare(b.name));
    }

    filter(search?: string, dates?: string[]): UserADMModel[] {
        if (!search && !dates?.length) return this.data;

        let filteredUsers = [...this.data];

        if (dates?.length === 2) {
            const [start, end] = dates.map((date) => new Date(date).getTime());
            filteredUsers = filteredUsers.filter((user) => {
                const userDate = new Date(user.created_at).getTime();
                return userDate >= start && userDate <= end;
            });
        }

        if (search) {
            filteredUsers = filteredUsers.filter((user) =>
                JSON.stringify(user, ["name", "email", "mobile"])
                    .toLowerCase()
                    .includes(search.toLowerCase())
            );
        }

        return this.orderByName(filteredUsers);
    }

    async filterBack(search?: string, page?: number): Promise<UserADMModel[]> {
    
        const params = [];
        if (search) params.push(`search=${search}`);
        if (page) params.push(`page=${page}`);
        const query = params.length ? `?${params.join("&")}` : "";
    
        const response = await this.httpGetClient.request({
            url: `${this.url}${query}`,
            method: "get",
        });
        
        const remoteUsers = response.body;
    
        if (
            remoteUsers &&
            typeof remoteUsers === "object" &&
            "users" in remoteUsers &&
            "pagination" in remoteUsers
        ) {
            const usersData = remoteUsers as { users: UserADMModel[]; pagination: Pagination };
            this.pagination = usersData.pagination;
            this.data = this.format(usersData.users);
    
            return this.data;
        }
        
        switch (response.statusCode) {
            case HttpStatusCode.ok:
                console.warn("Resposta OK, mas sem dados de usuários.");
                return [];
            case HttpStatusCode.forbidden:
                console.error("Acesso negado.");
                throw new AccessDeniedError();
            case HttpStatusCode.noContent:
                console.warn("Resposta sem conteúdo.");
                return [];
            default:
                console.error("Erro inesperado.");
                throw new UnexpectedError();
        }
    }
    
    getPagination(): Pagination | null {
        return this.pagination;
    }
    
}
