import { Text } from "alisson-application";
import { toast } from "react-toastify";
import { Container as Button } from "@/presentation/components/Button";
import { Loader } from "@/presentation/components/Loader";
import { useModal } from "@/main/contexts/modal";
import imgLink from "@/presentation/assets/icone/link-voucher-modal.svg";
import imgCopyLink from "@/presentation/assets/icone/copy-link.svg";
import imgClose from "@/presentation/assets/icone/close.svg";
import { Container } from "./styles";

export interface ModalLinkVoucherProps {
    links: string[];
    loading: boolean;
    vouchers: string[];
}

export function ModalLinkVoucher(props: ModalLinkVoucherProps) {
    const { links, loading, vouchers } = props;
    const { handleClose } = useModal();

    function copy(value: string) {
        navigator.clipboard.writeText(value);
        toast.success("Link copiado");
    }

    return (
        <Container>
            <div className="container-header">
                <Text as="h1">
                    <img src={imgLink} alt="Link do Voucher" /> Link do Voucher
                </Text>
            </div>

            {loading ? (
                <Loader width={30} height={30} />
            ) : (
                links?.map((item: any, index: number) => (
                    <div className="link-voucher" key={item}>
                        <span>
                            {vouchers[index].split("-")[0]} {item}
                        </span>
                        <button onClick={() => copy(item)}>
                            <img src={imgCopyLink} alt="Link do Voucher" />
                        </button>
                    </div>
                ))
            )}

            <div className="container-btns">
                <Button onClick={() => handleClose("linkVoucher")} mb="15px">
                    OK
                </Button>
            </div>

            <button
                className="btn-close"
                onClick={() => handleClose("linkVoucher")}
            >
                <img src={imgClose} alt="close" />
            </button>
        </Container>
    );
}
