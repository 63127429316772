import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import {
    ActionProductsAddModel,
    ActionProductsCustomizeParms,
    DetailActionMetricsFormatted,
    ActionProductsGroupParams,
    ActionProductsGroupListModel,
    ActionProductsGroupUpdateParams,
} from "@/domain/models";
import { DataActionProductsListModel } from "@/domain/models/actionProductsListModel";
import { useDetailAction } from "@/main/hooks/detailAction";
import { useModal } from "@/main/contexts/modal";
import { toast } from "react-toastify";
import { ActionProductsAddModelResponse } from "@/domain/useCases";
import { FormikHelpers } from "formik";

interface UseDetailActionVoucherProps {
    children: React.ReactNode | React.ReactNode[];
}
export interface UseStateDetailActionVoucher {
    handleRemoveProductsFromGroup: (
        groupId: number,
        productIds: number[]
    ) => Promise<void>;
    handleAddProductsToGroup: (
        groupId: number,
        productIds: number[]
    ) => Promise<void>;
    addVoucher(
        params: ActionProductsAddModel
    ): Promise<ActionProductsAddModelResponse>;
    handleCreateGroup(
        values: Omit<ActionProductsGroupParams, "action_id">,
        action: FormikHelpers<Omit<ActionProductsGroupParams, "action_id">>
    ): Promise<void>;

    handleUpdateGroup(
        values: Omit<ActionProductsGroupUpdateParams, "group_id">,
        action: FormikHelpers<Omit<ActionProductsGroupUpdateParams, "group_id">>
    ): Promise<void>;

    handleDeleteGroup(id: number): Promise<void>;

    handleSoftDeleteProduct(productId: number): Promise<void>;

    handleRestoreProduct(productId: number): Promise<void>;

    updateProducts(product: ActionProductsCustomizeParms): void;

    reorderVouchers(vouchers: any): Promise<void>;

    productsChoice: DataActionProductsListModel[];
    setProductsChoice: (productsChoice: DataActionProductsListModel[]) => void;
    dataMetrics: DetailActionMetricsFormatted;
    loading: boolean;

    groups: ActionProductsGroupListModel[];

    group: ActionProductsGroupListModel;
    setGroup: (group: ActionProductsGroupListModel) => void;

    deleteImgVoucher: (
        variation_id: number,
        product_id: number,
        actionId: number,
        value: any
    ) => void;
}

const Context = createContext<UseStateDetailActionVoucher>(
    {} as UseStateDetailActionVoucher
);

export function UseDetailActionVoucherProvider(
    props: UseDetailActionVoucherProps
) {
    const { children } = props;
    const { voucher, stateHistory } = useDetailAction();
    const { handleClose } = useModal();
    const [loading, setLoading] = useState(false);
    const [dataMetrics, setDataMetrics] =
        useState<DetailActionMetricsFormatted>(
            {} as DetailActionMetricsFormatted
        );
    const [groups, setGroups] = useState<ActionProductsGroupListModel[]>([]);
    const [group, setGroup] = useState<ActionProductsGroupListModel>(
        {} as ActionProductsGroupListModel
    );
    const [productsChoice, setProductsChoice] = useState<
        DataActionProductsListModel[]
    >([]);

    async function fetchMetrics() {
        try {
            const response = await voucher.loadMetrics({
                action_id: stateHistory.id,
            });

            setDataMetrics(response);
        } catch (error) {
            toast.error("Não foi possível carregar os detalhes da ação");
        }
    }

    async function fetchGroup() {
        try {
            const response = await voucher.loadGroup(stateHistory.id);

            setGroups(response);
        } catch (error) {
            console.log(error);
        }
    }

    async function addVoucher(
        params: ActionProductsAddModel
    ): Promise<ActionProductsAddModelResponse> {
        // eslint-disable-next-line no-useless-catch
        try {
            const response = await voucher.productAdd(params);
            await load();
            return response;
        } catch (error) {
            throw error;
        }
    }

    async function updateProducts(product: ActionProductsCustomizeParms) {
        // eslint-disable-next-line no-useless-catch
        try {
            await voucher.updateProduct(product);
            await load();
            toast.success("Produto atualizado com sucesso");
        } catch (error) {
            throw error;
        }
    }

    async function deleteImgVoucher(
        variation_id: number,
        product_id: number,
        actionId: number,
        value: string
    ) {
        let params: ActionProductsCustomizeParms;
        if (value === "template_image") {
            params = {
                unset_template_image: true,
                product_id: product_id,
                action_id: actionId,
                variation_id: variation_id,
            };
        } else if (value === "img_modal") {
            params = {
                unset_img_modal: true,
                product_id: product_id,
                action_id: actionId,
                variation_id: variation_id,
            };
        } else {
            //unset_img_store
            params = {
                unset_img_store: true,
                product_id: product_id,
                action_id: actionId,
                variation_id: variation_id,
            };
        }
        // eslint-disable-next-line no-useless-catch
        try {
            await voucher.updateProduct(params);
            await load();
            toast.success("Produto atualizado com sucesso");
        } catch (error) {
            throw error;
        }
    }

    async function handleCreateGroup(
        values: Omit<ActionProductsGroupParams, "action_id">,
        action: FormikHelpers<Omit<ActionProductsGroupParams, "action_id">>
    ) {
        if (values.product_configuration_ids.length === 0) {
            action.setFieldError(
                "product_configuration_ids",
                "Selecione pelo menos um produto"
            );
            return;
        }

        try {
            await voucher.createGroup({
                ...values,
                action_id: stateHistory.id,
            });
            await load();
            toast.success("Grupo criado com sucesso");
            action.resetForm();
            handleClose("createGroup");
        } catch (error) {
            console.log(error);
            handleClose("createGroup");
        }
    }

    async function handleUpdateGroup(
        values: Omit<ActionProductsGroupUpdateParams, "group_id">,
        action: FormikHelpers<Omit<ActionProductsGroupUpdateParams, "group_id">>
    ) {
        if (values.product_configuration_ids.length === 0) {
            action.setFieldError(
                "product_configuration_ids",
                "Selecione pelo menos um produto"
            );
            return;
        }

        if (!group.id) {
            toast.error("Não foi possível atualizar o grupo");
            return;
        }

        try {
            await voucher.updateGroup({
                ...values,
                group_id: group.id,
            });
            await load();
            toast.success("Grupo atualizado com sucesso");
            action.resetForm();
            handleClose("editGroup");
        } catch (error) {
            console.log(error);
            handleClose("editGroup");
        }
    }

    async function handleDeleteGroup(id: number) {
        try {
            await voucher.deleteGroup({
                group_id: id,
            });

            setGroup({} as ActionProductsGroupListModel);
            await load();
            toast.success("Grupo deletado com sucesso");
            handleClose("alert");
        } catch (error) {
            console.log(error);
            handleClose("alert");
        }
    }

    async function handleRemoveProductsFromGroup(
        groupId: number,
        productIds: number[]
    ) {
        setLoading(true);
        try {
            const removalPromises = productIds.map((productId) =>
                voucher.unaddProductFromGroup(groupId, productId)
            );
            await Promise.all(removalPromises);
            toast.success("Produtos removidos do grupo com sucesso.");
            await load();
        } catch (error) {
            console.error(error);
            toast.error("Erro ao remover produtos do grupo.");
        } finally {
            setLoading(false);
        }
    }

    async function handleAddProductsToGroup(
        groupId: number,
        productIds: number[]
    ) {
        setLoading(true);
        try {
            const addPromises = productIds.map((productId) =>
                voucher.addProductToGroup(groupId, productId)
            );
            await Promise.all(addPromises);
            toast.success("Produtos adicionados ao grupo com sucesso.");
            await load();
        } catch (error) {
            console.error(error);
            toast.error("Erro ao adicionar produtos ao grupo.");
        } finally {
            setLoading(false);
        }
    }

    async function handleSoftDeleteProduct(productId: number) {
        setLoading(true);
        try {
            await voucher.softDeleteProduct({ product_id: productId });
            await load();
            toast.success("Produto deletado logicamente com sucesso.");
        } catch (error) {
            console.error(error);
            toast.error("Erro ao deletar produto.");
        } finally {
            setLoading(false);
        }
    }

    async function reorderVouchers(vouchers: any): Promise<void> {
        try {
            await voucher.reorderVouchers(vouchers);
            toast.success("Vouchers reordenados com sucesso.");
            await load();
        } catch (error) {
            console.error(error);
            toast.error("Erro ao reordenar vouchers.");
        }
    }

    const load = useCallback(async () => {
        setLoading(true);
        try {
            await Promise.all([fetchMetrics(), fetchGroup()]);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }, []);

    async function handleRestoreProduct(productId: number) {
        setLoading(true);
        try {
            await voucher.restoreProduct({ product_id: productId });
            await load();
            toast.success("Produto restaurado com sucesso.");
        } catch (error) {
            console.error(error);
            toast.error("Erro ao restaurar produto.");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <Context.Provider
            value={{
                productsChoice,
                setProductsChoice,
                dataMetrics,
                updateProducts,
                loading,
                addVoucher,
                handleCreateGroup,
                handleUpdateGroup,
                handleDeleteGroup,
                groups,
                setGroup,
                group,
                handleRemoveProductsFromGroup,
                handleAddProductsToGroup,
                handleSoftDeleteProduct,
                handleRestoreProduct,
                reorderVouchers,
                deleteImgVoucher,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useDetailActionVoucher() {
    const context = useContext(Context);
    return context;
}
