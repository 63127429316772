import styled from "styled-components";
import { Container as BoxVoucherModal } from "@/presentation/components/BoxVoucherModal/styles";

interface ContainerProps {
    deleted: boolean;
}

export const ActiveContainer = styled.div<ContainerProps>`
    display: flex;
    border: 1px solid #9d9d9d;
    border-radius: 5px;
    padding: 80px 30px 100px;
    margin-bottom: 15px;
    height: auto;
    position: relative;
    transition: all 0.5s ease 0s;

    .container-detalhes {
        position: absolute;
        top: 30px;
        left: 30px;
        display: flex;
        padding-right: 70px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        h5 {
            &:first-child {
                margin-right: 32px;
            }
        }
    }

    .first-details-div {
        display: flex;
    }

    .second-details-div {
        display: flex;
        align-items: baseline;
        width: 585px;
        justify-content: space-between;
    }

    .restore-div {
        display: flex;
        height: 30px;
    }

    .restore-div img {
        width: 19px;
        height: 19px;
    }

    .restore-div:hover {
        cursor: pointer;
    }

    .restore-div p {
        display: flex;
        margin-left: 8px;
        font-style: normal;
        font-size: 0.875rem;
        color: rgb(111, 62, 152);
        line-height: 100%;
        font-family: Lato;
        font-weight: 700;
        margin-top: 5px;
    }

    .delete-div {
        height: 30px;
        display: flex;
    }

    .delete-div p {
        margin-left: 8px;
        font-style: normal;
        font-size: 0.875rem;
        color: rgb(111, 62, 152);
        line-height: 100%;
        font-family: Lato;
        font-weight: 700;
        margin-top: 5px;
    }

    .delete-div img {
        width: 19px;
        height: 19px;
    }

    .container-input {
        margin-bottom: 0px;
    }

    #openLinkInNewWindow {
        display: none;
    }

    .rdw-link-modal-target-option {
        display: none;
    }

    #linkTitle {
        height: 40px;
    }

    .rdw-link-modal {
        position: absolute;
        top: 35px;
        left: 5px;
        display: flex;
        flex-direction: column;
        width: 400px !important;
        height: 220px !important;
        border: 1px solid #f1f1f1;
        padding: 15px;
        border-radius: 2px;
        z-index: 100;
        background: white;
        box-shadow: 3px 3px 5px #bfbdbd;
    }

    .esq {
        width: 500px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-direction: column;

        .container-field {
            h5 {
                padding-left: 10px;
            }
        }
        .input-text {
            height: 325px;
            width: 390px;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-direction: column;
        }
        .saiba-mais {
            margin-top: 20px;
            .container-saiba-mais {
                height: 0;
                overflow: hidden;
                transition: all 0.5s ease 0s;
                &.active {
                    height: auto;
                    transition: all 0.5s ease 0s;
                }
            }
            .link-saiba-mais {
                font-style: normal;
                font-size: 0.875rem;
                color: rgb(241, 126, 33);
                line-height: 100%;
                font-family: Lato;
                font-weight: 700;
                margin-bottom: 10px;
                cursor: pointer;
                img {
                    margin-left: 10px;
                    transform: rotateX(0deg);
                    transition: all 0.5s;
                }
                &.active {
                    img {
                        transform: rotateX(180deg);
                        transition: all 0.5s;
                    }
                }
            }
        }
    }

    .itens-center {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
        width: 137px;
        margin-left: 31px;
        padding-top: 27px;
        height: 260px;
        .container-input {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: start;
            input {
                width: 90px;
                height: 40px;
            }
        }

        .item {
            display: flex;
            align-items: center;
            &:nth-of-type(1) {
                align-items: flex-start;
            }
            &:nth-of-type(2) {
                align-items: center;
            }

            &:nth-of-type(4) {
                align-items: flex-end;
            }
            .container-img {
                img {
                    width: 31px;
                    height: 31px;
                }
            }
            .container-text {
                margin-left: 14px;
                h5 {
                    margin-bottom: 10px;
                }
                p {
                    margin-bottom: 0px;
                }
            }
        }
    }

    .dir {
        display: flex;
        margin-left: auto;
        ${BoxVoucherModal} {
            margin-right: 14px;
            &:last-child {
                margin-right: 0px;
            }
        }
    }
    .custom-padding {
        padding-left: 10px;
    }
`;

export const InactiveContainer = styled(ActiveContainer)`
    &:hover .deleted-overlay {
        display: ${(props) => (props.deleted ? "flex" : "none")};
    }

    .deleted-overlay {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 30px;
        align-items: end;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        color: #ff0000;
        z-index: 10;
        font-style: normal;
        font-size: 0.875rem;
        color: rgb(111, 62, 152);
        line-height: 100%;
        font-family: Lato;
    }

    border: 1px solid #e2e2e2;

    .first-details-div h5 {
        color: #9d9d9d;
    }
    .container-input h5 {
        color: #9d9d9d;
    }
    .container-input input {
        background-color: #f5f5f5;
        border: 1px solid #e2e2e2;
    }
    .container-field h5 {
        color: #9d9d9d;
    }
    .container-field input {
        background-color: #f5f5f5;
        border: 1px solid #e2e2e2;
        color: #9d9d9d;

        &::placeholder {
            color: #9d9d9d;
        }

        &::-webkit-input-placeholder {
            color: #9d9d9d;
        }

        &::-moz-placeholder {
            color: #9d9d9d;
        }

        &:-ms-input-placeholder {
            color: #9d9d9d;
        }

        &:-moz-placeholder {
            color: #9d9d9d;
        }
    }
    .container-input.seta-baixo::before {
        background-color: #9d9d9d;
    }
    .container-input::before {
        background-color: #9d9d9d;
    }
    .esq .saiba-mais .link-saiba-mais {
        color: #9d9d9d;
    }
    .container-input input,
    .container-input textarea {
        color: #9d9d9d;
    }
`;
