import { useState, useEffect } from "react";
import { Container as Button } from "@/presentation/components/Button";
import { Box } from "alisson-application";
import { IOSSwitch } from "@/presentation/components/Switch";
import InputMask from "react-input-mask";
import { useUserContext } from "@/main/hooks/userADM";
import {
    Container,
    Backdrop,
    Popup,
    PopupHeader,
    PopupBody,
    PopupFooter,
    Input,
    Select,
    AddButton,
    Divider,
    SwitchWrapper,
    SwitchLabel,
    SearchInputWrapper,
    SearchWrapper,
    SearchIcon,
    ActionItemStyled,
    PopupBodyMessage,
    AddButtonMessage,
    PopupMessage,
    BackdropMessage,
} from "./styles";

import AddUserIcon from "@/presentation/assets/icone/add-user-icon.svg";
import AddUserADMPopUpIcon from "@/presentation/assets/icone/add-user-adm-pop-up-icon.svg";
import SearchIconImage from "@/presentation/assets/icone/search-icon.svg";
import { useContractorsActionsContext } from "@/main/hooks/userContractorActions";
import { useCreateADMUserContext } from "@/main/hooks/useCreateADMUserContext";

interface ActionItem {
    id: number;
    name: string;
    document: string | null;
}

export function SubHeaderEditUsersADM() {
    const { openPopupEdit, setOpenPopupEdit, userEdit, setUserEdit } =
        useUserContext();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isMessagePopupOpen, setIsMessagePopupOpen] = useState(false);
    const [messagePopupContent, setMessagePopupContent] = useState("");

    const [isAllowAllActionsChecked, setIsAllowAllActionsChecked] =
        useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedItems, setSelectedItems] = useState<ActionItem[]>([]);

    const profileMap: Record<string, number> = {
        MASTER: 1,
        "ATENDIMENTO YETZ": 4,
        COMERCIAL: 5,
        OPERACIONAL: 6,
        "ATENDIMENTO EXTERNO": 7,
    };
    const [profile, setProfile] = useState("MASTER");

    const [name, setName] = useState("");
    const [cpf, setCpf] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");

    const [errors, setErrors] = useState<{
        name?: string;
        cpf?: string;
        mobile?: string;
        password?: string;
        email?: string;
        selectedItems?: string;
    }>({});

    const { contractorsActions } = useContractorsActionsContext();
    const { createUser } = useCreateADMUserContext();
    const { handleUserUpdate } = useUserContext();

    // const expandedList: ActionItem[] = contractorsActions.flatMap((item) =>
    //     item.actions.map(
    //         (action: { actionId: number; actionName: string }) => ({
    //             uniqueId: `${item.id}-${action.actionId}`,
    //             name: item.name,
    //             cnpj: item.document || "",
    //             actionName: action.actionName,
    //         })
    //     )
    // );

    const expandedList = contractorsActions;

    const filteredList = expandedList.filter(
        (entry) =>
            (entry.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                entry?.document?.includes(searchTerm)) &&
            !selectedItems.some((selected) => selected.id === entry.id)
    );

    useEffect(() => {
        if (userEdit) {
            setName(userEdit.name);
            setCpf(userEdit.document);
            setMobile(userEdit.mobile);
            setEmail(userEdit.email);
            setProfile(userEdit.profile.name);
            if (userEdit?.contractors?.length) {
                setIsAllowAllActionsChecked(false);
            }
            const tempSelectedItems: ActionItem[] = [];
            expandedList.map((entry) =>
                userEdit?.contractors?.map((u: any) => {
                    if (u.id === entry.id) {
                        tempSelectedItems.push(entry);
                    }
                })
            );
            setSelectedItems(tempSelectedItems);
        } else {
            setName("");
            setCpf("");
            setMobile("");
            setEmail("");
            setProfile("MASTER");
            setIsAllowAllActionsChecked(true);
        }
    }, [userEdit]);

    const handleSelectItem = (item: ActionItem) => {
        if (!selectedItems.some((i) => i.id === item.id)) {
            setSelectedItems((prev) => [...prev, item]);
        }
    };

    const handleRemoveItem = (uniqueId: number) => {
        setSelectedItems((prev) => prev.filter((item) => item.id !== uniqueId));
    };

    const togglePopup = (valor: boolean) => {
        setUserEdit(false);
        setIsPopupOpen(valor);
    };

    const handleAllowAllActionsChange = () => {
        setIsAllowAllActionsChecked(!isAllowAllActionsChecked);
    };

    function validateName(value: string) {
        return value.trim().length > 0;
    }

    function validateMobile(value: string) {
        const phoneDigits = value.replace(/[^\d]/g, "");
        return phoneDigits.length >= 10;
    }

    function validateCPF(cpfValue: string): boolean {
        const cleaned = cpfValue.replace(/[^\d]/g, "");
        if (cleaned.length !== 11 || /^(\d)\1+$/.test(cleaned)) {
            return false;
        }
        let sum = 0;
        let remainder;

        for (let i = 1; i <= 9; i++) {
            sum += parseInt(cleaned.substring(i - 1, i)) * (11 - i);
        }
        remainder = (sum * 10) % 11;
        if (remainder === 10 || remainder === 11) remainder = 0;
        if (remainder !== parseInt(cleaned.substring(9, 10))) return false;

        sum = 0;
        for (let i = 1; i <= 10; i++) {
            sum += parseInt(cleaned.substring(i - 1, i)) * (12 - i);
        }
        remainder = (sum * 10) % 11;
        if (remainder === 10 || remainder === 11) remainder = 0;
        if (remainder !== parseInt(cleaned.substring(10, 11))) return false;

        return true;
    }

    function validateEmail(emailValue: string): boolean {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(emailValue.trim());
    }

    const sanitizeCPF = (cpf: string): string => {
        return cpf.replace(/\D/g, "");
    };

    const handleAddUser = async () => {
        const newErrors: {
            name?: string;
            cpf?: string;
            mobile?: string;
            password?: string;
            email?: string;
            selectedItems?: string;
        } = {};

        let hasError = false;

        if (!validateName(name)) {
            newErrors.name = "O campo Nome é obrigatório.";
            hasError = true;
        }
        if (!cpf.trim()) {
            newErrors.cpf = "O campo CPF é obrigatório.";
            hasError = true;
        } else if (!validateCPF(cpf)) {
            newErrors.cpf = "CPF inválido. Verifique e tente novamente.";
            hasError = true;
        }
        if (!validateMobile(mobile)) {
            newErrors.mobile = "Por favor, insira um telefone válido.";
            hasError = true;
        }

        if (!validateEmail(email)) {
            newErrors.email = "Por favor, insira um e-mail válido.";
            hasError = true;
        }

        if (!isAllowAllActionsChecked && selectedItems.length === 0) {
            newErrors.selectedItems = "Selecione pelo menos uma ação.";
            hasError = true;
        }

        setErrors(newErrors);
        if (hasError) {
            console.error("Erro ao adicionar usuário. Detalhes do erro:", {
                newErrors,
                hasError,
            });
            return;
        }

        try {
            const sanitizedCPF = sanitizeCPF(cpf);

            const actionsPayload = selectedItems.map((item) => {
                return { id: item.id };
            });

            const payload = {
                name,
                cpf: sanitizedCPF,
                email,
                mobile,
                access_polices_id: String(profileMap[profile]),
                contractors: actionsPayload,
            };

            if (openPopupEdit) {
                await handleUserUpdate(payload, userEdit.id);
                setMessagePopupContent("Usuário atualizado com sucesso!");
                setIsMessagePopupOpen(true);
                setOpenPopupEdit(false);
                setUserEdit(false);
            } else {
                console.debug("Tentando criar usuário com payload:", payload);
                await createUser(payload);
                setMessagePopupContent("Usuário criado com sucesso!");
                setIsMessagePopupOpen(true);
            }
        } catch (error) {
            console.log("error", error);
            // setMessagePopupContent(
            //     "Erro ao criar usuário, tente novamente mais tarde."
            // );
            // setIsMessagePopupOpen(true);
        }
    };

    const handleMessagePopupClose = () => {
        setIsMessagePopupOpen(false);
        setIsPopupOpen(false);
        setName("");
        setCpf("");
        setMobile("");
        setEmail("");
        setProfile("MASTER");
        setSelectedItems([]);
        setSearchTerm("");
        setIsAllowAllActionsChecked(true);
        setErrors({});
    };

    return (
        <Container>
            <Box ml="auto" width={200}>
                <Button onClick={() => togglePopup(true)}>
                    <img
                        src={AddUserIcon}
                        alt="Ícone de adicionar usuário"
                        style={{
                            width: "16px",
                            height: "16px",
                            marginRight: "8px",
                        }}
                    />
                    Adicionar Usuário
                </Button>
            </Box>

            {isMessagePopupOpen && (
                <BackdropMessage>
                    <PopupMessage>
                        <PopupBodyMessage>
                            <p>{messagePopupContent}</p>
                        </PopupBodyMessage>
                        <PopupFooter>
                            <AddButtonMessage onClick={handleMessagePopupClose}>
                                OK
                            </AddButtonMessage>
                        </PopupFooter>
                    </PopupMessage>
                </BackdropMessage>
            )}

            {isPopupOpen || openPopupEdit ? (
                <>
                    <Backdrop
                        onClick={() => {
                            togglePopup(false);
                            setOpenPopupEdit(false);
                            handleMessagePopupClose();
                        }}
                    />
                    <Popup>
                        <PopupHeader>
                            <div>
                                <img
                                    src={AddUserADMPopUpIcon}
                                    alt="Ícone de adicionar usuário"
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "8px",
                                    }}
                                />
                                <h2>
                                    {openPopupEdit
                                        ? "EDITAR USUÁRIO"
                                        : "ADICIONAR USUÁRIO"}{" "}
                                </h2>
                            </div>
                        </PopupHeader>
                        <Divider />

                        <PopupBody>
                            <Input
                                placeholder="Nome"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                style={{
                                    borderColor: errors.name
                                        ? "red"
                                        : "#ced4da",
                                }}
                            />
                            {errors.name && (
                                <span
                                    style={{
                                        color: "red",
                                        fontSize: "12px",
                                    }}
                                >
                                    {errors.name}
                                </span>
                            )}

                            <InputMask
                                mask="999.999.999-99"
                                value={cpf}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setCpf(e.target.value)}
                            >
                                {(
                                    inputProps: React.InputHTMLAttributes<HTMLInputElement>
                                ) => (
                                    <Input
                                        {...inputProps}
                                        autoComplete="off"
                                        placeholder="CPF"
                                        style={{
                                            borderColor: errors.cpf
                                                ? "red"
                                                : "#ced4da",
                                            marginTop: "12px",
                                        }}
                                    />
                                )}
                            </InputMask>
                            {errors.cpf && (
                                <span
                                    style={{
                                        color: "red",
                                        fontSize: "12px",
                                    }}
                                >
                                    {errors.cpf}
                                </span>
                            )}

                            <InputMask
                                mask="(99) 99999-9999"
                                value={mobile}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setMobile(e.target.value)}
                            >
                                {(
                                    inputProps: React.InputHTMLAttributes<HTMLInputElement>
                                ) => (
                                    <Input
                                        {...inputProps}
                                        autoComplete="off"
                                        placeholder="Telefone"
                                        style={{
                                            borderColor: errors.mobile
                                                ? "red"
                                                : "#ced4da",
                                            marginTop: "12px",
                                        }}
                                    />
                                )}
                            </InputMask>
                            {errors.mobile && (
                                <span
                                    style={{
                                        color: "red",
                                        fontSize: "12px",
                                    }}
                                >
                                    {errors.mobile}
                                </span>
                            )}

                            <Input
                                placeholder="E-mail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                style={{
                                    borderColor: errors.email
                                        ? "red"
                                        : "#ced4da",
                                    marginTop: "12px",
                                }}
                            />
                            {errors.email && (
                                <span
                                    style={{
                                        color: "red",
                                        fontSize: "12px",
                                    }}
                                >
                                    {errors.email}
                                </span>
                            )}

                            <Select
                                value={profile}
                                onChange={(e) => setProfile(e.target.value)}
                                style={{ marginTop: "12px" }}
                            >
                                {Object.keys(profileMap).map((profileName) => (
                                    <option
                                        key={profileName}
                                        value={profileName}
                                    >
                                        {profileName}
                                    </option>
                                ))}
                            </Select>

                            <SwitchWrapper>
                                <IOSSwitch
                                    checked={isAllowAllActionsChecked}
                                    onChange={handleAllowAllActionsChange}
                                />
                                <SwitchLabel>
                                    Visualizar ações de todos os clientes
                                </SwitchLabel>
                            </SwitchWrapper>

                            {!isAllowAllActionsChecked && (
                                <>
                                    <span className="msg-atencao">
                                        <strong>Atenção:</strong> o usuário
                                        poderá visualizar apenas as ações
                                        vinculadas aos clientes selecionados.
                                    </span>

                                    <SearchInputWrapper>
                                        <SearchWrapper>
                                            <SearchIcon
                                                src={SearchIconImage}
                                                alt="Ícone de busca"
                                            />
                                            <Input
                                                placeholder="Buscar por Cliente"
                                                value={searchTerm}
                                                onChange={(e) =>
                                                    setSearchTerm(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </SearchWrapper>

                                        <div
                                            style={{
                                                width: "100%",
                                                background: "#FFF",
                                                maxHeight: "100px",
                                                overflowY: "auto",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            {filteredList.map((item) => (
                                                <ActionItemStyled
                                                    key={item.id}
                                                    onClick={() =>
                                                        handleSelectItem(item)
                                                    }
                                                >
                                                    {item.name}
                                                </ActionItemStyled>
                                            ))}
                                        </div>
                                    </SearchInputWrapper>

                                    {errors.selectedItems && (
                                        <span
                                            style={{
                                                color: "red",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {errors.selectedItems}
                                        </span>
                                    )}

                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: "10px",
                                            marginTop: "8px",
                                        }}
                                    >
                                        {selectedItems.map((item) => (
                                            <div
                                                key={item.id}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    background: "#F5F5F5",
                                                    borderRadius: "10px",
                                                    padding: "5px 10px",
                                                    boxShadow:
                                                        "0px 1px 2px rgba(0, 0, 0, 0.1)",
                                                    fontFamily: "Lato",
                                                    fontSize: "14px",
                                                    color: "#5E5F5F",
                                                }}
                                            >
                                                <span>{item.name}</span>
                                                <button
                                                    onClick={() =>
                                                        handleRemoveItem(
                                                            item.id
                                                        )
                                                    }
                                                    style={{
                                                        background:
                                                            "transparent",
                                                        border: "none",
                                                        color: "#5E5F5F",
                                                        cursor: "pointer",
                                                        marginLeft: "8px",
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </PopupBody>

                        <PopupFooter>
                            <AddButton onClick={handleAddUser}>
                                {openPopupEdit ? "Editar" : "Adicionar"}
                            </AddButton>
                        </PopupFooter>
                    </Popup>
                </>
            ) : null}
        </Container>
    );
}
