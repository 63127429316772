import { Text } from "alisson-application";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import { validationLogin, initialValues, IValues } from "./config";
import { useModal } from "@/main/contexts/modal";
import { CharCounter } from "@/presentation/components/CharCounter";
import { Container as Button } from "@/presentation/components/Button";
import { useDetailActionCustumize } from "@/presentation/components/DetailContent/hooks/detailActionCustumize";
import { useDetailAction } from "@/main/hooks/detailAction";
import { useEffect, useState } from "react";
import { IOSSwitch } from "@/presentation/components/Switch";

export function FormActionCustomRescued() {
    const { loading, updatePremioResgate } = useDetailActionCustumize();
    const { findActionData } = useDetailAction();
    const [values, setValues] = useState<IValues>(initialValues);

    const { setState, state } = useModal();

    useEffect(() => {
        if (findActionData.id) {
            setValues({
                my_rewards_title: findActionData.my_rewards_title || "",
                my_rewards_button_text: findActionData.my_rewards_button_text || "",
                my_rewards_subtitle: findActionData.my_rewards_subtitle || "",
                disable_text_my_rewards: findActionData.disable_text_my_rewards || 0,
            });
        }
    }, [findActionData]);

    return (
        <Formik
            validateOnMount={false}
            enableReinitialize
            initialValues={values}
            validationSchema={validationLogin}
            onSubmit={async (formValues) => {
                setState({
                    ...state,
                    alert: {
                        active: true,
                        onclick: async () => {
                            await updatePremioResgate(formValues);
                        },
                        text: "Você deseja realmente editar esses campos?",
                    },
                });
            }}
        >
            {(props: FormikProps<IValues>) => {
                const {
                    values,
                    handleChange: handleChangeFormik,
                    setFieldValue,
                    dirty,
                } = props;

                return (
                    <Form data-testid="form-login">
                        <Text as="h5" ml="6px" mb="10px">
                            Texto 01
                        </Text>
                        <div className="container-input">
                            <textarea
                                value={values.my_rewards_title || ""}
                                name="my_rewards_title"
                                disabled={loading}
                                maxLength={90}
                                onChange={(e) => {
                                    handleChangeFormik(e);
                                }}
                            />
                            <CharCounter
                                total={90}
                                value={values.my_rewards_title || ""}
                            />
                            <ErrorMessage
                                name="my_rewards_title"
                                data-testid="error"
                                component="span"
                            />
                        </div>

                        <Text as="h5" ml="6px" mb="10px">
                            Botão resgatar prêmio
                        </Text>

                        <div className="container-input">
                            <input
                                type="text"
                                value={values.my_rewards_button_text || ""}
                                name="my_rewards_button_text"
                                disabled={loading}
                                maxLength={25}
                                onChange={(e) => {
                                    handleChangeFormik(e);
                                }}
                            />
                            <CharCounter
                                total={25}
                                value={values.my_rewards_button_text || ""}
                            />
                            <ErrorMessage
                                name="my_rewards_button_text"
                                data-testid="error"
                                component="span"
                            />
                        </div>

                        <Text as="h5" ml="6px" mb="10px">
                            Texto 02
                        </Text>

                        <div className="container-input">
                            <textarea
                                value={values.my_rewards_subtitle || ""}
                                name="my_rewards_subtitle"
                                disabled={loading}
                                maxLength={150}
                                onChange={(e) => {
                                    handleChangeFormik(e);
                                }}
                            />
                            <CharCounter
                                total={150}
                                value={values.my_rewards_subtitle || ""}
                            />
                            <ErrorMessage
                                name="my_rewards_subtitle"
                                data-testid="error"
                                component="span"
                            />
                        </div>

                        <div className="container-input">
                            <Text as="h5" ml="6px" mb="10px">
                                Visualizar na horizontal?
                            </Text>
                            <IOSSwitch
                                name="disable_text_my_rewards"
                                value={values.disable_text_my_rewards ? 0 : 1}
                                checked={
                                    values.disable_text_my_rewards
                                        ? true
                                        : false
                                }
                                onChange={(e) =>
                                    setFieldValue(
                                        "disable_text_my_rewards",
                                        e.target.checked ? 1 : 0
                                    )
                                }
                            />
                            <ErrorMessage
                                name="disable_text_my_rewards"
                                data-testid="error"
                                component="span"
                            />
                        </div>

                        <Button 
                            type="submit" 
                            disabled={loading || !dirty}
                        >
                            Salvar
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
}
