import { HttpStatusCode } from "@/data/protocols/http";
import { HttpClient } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { ActionProductsCustomizeParms } from "@/domain/models";

import { ActionProductsCustomize } from "@/domain/useCases";
export type keyOfActionProductsCustomize<T> = keyof T;
export class RemoteActionProductsCustomize implements ActionProductsCustomize {
    constructor(
        protected readonly httpClient: HttpClient,
        protected readonly urlProductsCustomize: string = "action/products/customize"
    ) {}

    async updateProduct(params: ActionProductsCustomizeParms): Promise<void> {
        if(params.unset_img_modal || params.unset_img_store || params.unset_template_image){
            const response = await this.httpClient.request({
                method: "post",
                url: this.urlProductsCustomize,
                body: params,
            });
            switch (response.statusCode) {
                case HttpStatusCode.ok:
                    return response.body;

                case HttpStatusCode.unauthorized:
                    throw new InvalidCredentialsErros();
                default:
                    throw new UnexpectedError();
            }
        }else{
            const response = await this.httpClient.request({
                method: "post",
                url: this.urlProductsCustomize,
                body: this.formatUpdateProduct(params),
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            switch (response.statusCode) {
                case HttpStatusCode.ok:
                    return response.body;

                case HttpStatusCode.unauthorized:
                    throw new InvalidCredentialsErros();
                default:
                    throw new UnexpectedError();
            }
        } 
    }

    formatUpdateProduct(params: ActionProductsCustomizeParms): FormData {
        const formData = new FormData();
        const removeKeys = ["template_image", "img_store", "img_modal"];

        const keys = Object.keys(
            params
        ) as keyOfActionProductsCustomize<ActionProductsCustomizeParms>[];

        keys.forEach((key) => {
            if (!removeKeys.includes(key)) {
                const value = params[key];
                if (typeof value === 'string' || value instanceof Blob) {
                    formData.append(key, value);
                } else if (typeof value === 'number') {
                    formData.append(key, value.toString());
                } 
            }
        });

        if (params.icon) {
            formData.append("icon", params.icon);
        }

        if (params.img_store) {
            formData.append("img_store", params.img_store);
        }

        if (params.img_modal) {
            formData.append("img_modal", params.img_modal);
        }

        if (params.template_image) {
            formData.append("template_image", params.template_image);
        }

        return formData;
    }
}
