import { useEffect, useState } from "react";
import { Text, Flex } from "alisson-application";
import { useDetailActionVoucher } from "@/presentation/components/DetailContent/hooks/detailActionVoucher";
import { useDetailAction } from "@/main/hooks/detailAction";
import { Container as Button } from "@/presentation/components/Button";
import { ActionItemQuantityVoucher } from "@/presentation/components/ActionItemQuantityVoucher";
import { useModal } from "@/main/contexts/modal";
import { toast } from "react-toastify";
import imgCard from "@/presentation/assets/icone/card-plus-roxo.svg";
import imgClose from "@/presentation/assets/icone/close.svg";
import { Container } from "./styles";

interface SendProducts {
    product_id: number;
    variation_id: number;
    quantity: number;
    levels: any;
}

export function ActionChoiceQuantityVoucher() {
    const { handleClose } = useModal();
    const { stateHistory } = useDetailAction();
    const { productsChoice, addVoucher, setProductsChoice } =
        useDetailActionVoucher();
    const [sendProducts, setSendProducts] = useState<SendProducts[]>([]);
    const [loading, setLoading] = useState(false);
    const [erroNivel, setErroNivel] = useState(0);

    function onchange(quantity: number, selectedItems: any[], id: number) {
        const update = sendProducts.map((product) => {
            if (product.variation_id === id) {
                return {
                    ...product,
                    quantity: quantity,
                    levels: selectedItems,
                };
            }
            return product;
        });

        setSendProducts(update);
    }

    async function handleAdd() {
        let erro = 0;

        sendProducts.map((s) => {
            if (s.levels.length === 0) {
                erro++;
                setErroNivel(s.variation_id);
                return;
            }
        });

        if (erro === 0) {
            setLoading(true);
            try {
                const response = await addVoucher({
                    action_id: stateHistory.id,
                    products: [...sendProducts],
                });

                toast.success(response.message);
                handleClose("selectVouchersQtd");
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    }

    function handleCloseModal() {
        setProductsChoice([]);
        handleClose("selectVouchersQtd");
    }

    useEffect(() => {
        const update = productsChoice.map((product) => {
            return {
                product_id: product.produto_id,
                variation_id: product.id,
                quantity: 0,
                levels: [],
            };
        });

        setSendProducts(update);
    }, [productsChoice]);

    return (
        <Container>
            <Flex mb="26px" flexDirection="column">
                <Flex alignItems="center">
                    <img src={imgCard} alt="" />
                    <Text ml="10px" as="h5">
                        SELECIONE OS NÍVEIS E A QUANTIDADE DE VOUCHERS
                    </Text>
                </Flex>
                <span className="span_importante">
                    <strong>Importante:</strong> é obrigatório definir os níveis
                    aos quais o voucher estará vinculado.
                </span>
            </Flex>

            {productsChoice.map((productChoice) => (
                <ActionItemQuantityVoucher
                    key={productChoice.id}
                    erroNivel={erroNivel === productChoice.id ? true : false}
                    {...productChoice}
                    onchange={onchange}
                />
            ))}

            {erroNivel !== 0 ? (
                <span className="span-erro">
                    Defina os níveis de todos os vouchers!
                </span>
            ) : null}

            <div className="container-btn">
                <Button
                    onClick={handleAdd}
                    isLoading={loading}
                    spinerColor="primary"
                    mb="8px"
                >
                    Avançar
                </Button>
                <button onClick={handleCloseModal}>Cancelar</button>
            </div>

            <div className="container-close">
                <button onClick={handleCloseModal}>
                    <img src={imgClose} alt="" />
                </button>
            </div>
        </Container>
    );
}
