import { useEffect, useState } from "react";
import Table, { Pagination } from "@/presentation/components/ActionTable";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useApi } from "@/main/contexts/apiContext";
import { Me } from "@/domain/models";
import { useDetailActionUser } from "@/presentation/components/DetailContent/hooks/detailActionUser";
import { useDetailAction } from "@/main/hooks/detailAction";
import { When } from "@/presentation/components/when";
import { Loader } from "@/presentation/components/Loader";
import { Container } from "./styles";
import { ButtonResetUser } from "@/presentation/components/ButtonResetUser";
import { Modal } from "@/presentation/components/Modal";
import { useModal } from "@/main/contexts/modal";
import { ModalLinkVoucher } from "@/presentation/components/ModalLinkVoucher";
import { Text, Button } from "alisson-application";
import { usePermissions } from "@/main/contexts/permissions";

import imgLink from "@/presentation/assets/icone/link-voucher-modal.svg";

export function DetailActionUserTable() {
    const { getStorage } = useApi();
    const userMe = getStorage<Me>("me");
    const { setUsers, users, loading, searchUser, fetchData, linkVoucher } =
        useDetailActionUser();
    const { user, stateHistory } = useDetailAction();
    const { hasPermission } = usePermissions();
    const { state, handleClose, handleOpen } = useModal();
    const [linksUser, setLinksUser] = useState([]);
    const [vouchersUser, setVouchersUser] = useState([]);
    const [loadingLinks, setLoadingLinks] = useState(false);

    const dados =
        users?.data?.map((user) => ({
            id: user.id,
            login: user.login,
            nome: user.name,
            reference_id: user.reference_id,
            level: user.level,
            primeiroAcesso: user.first_access,
            dataDoResgate: user?.rewarded_itens_date,
            mobile: user.mobile,
            validator: user.validator,
            voucher: user?.rewarded_itens,
            link: user?.rewarded_itens_link,
            action: "",
        })) || [];

    const hasTelefone = users?.data?.find((user) => user.mobile !== null);
    const hasValidator = users?.data?.find((user) => user.validator !== null);
    const hasLink = users?.data?.find(
        (user) => user.rewarded_itens_link.length >= 1
    );
    const hasDataResgate = users?.data?.find(
        (user) => user.rewarded_itens_date.length >= 1
    );
    const hasVoucher = users?.data?.find(
        (user) => user.rewarded_itens.length >= 1
    );

    async function getLinks(id: number, vouchers: any) {
        handleOpen("linkVoucher");
        setLoadingLinks(true);
        const { links } = await linkVoucher(id);
        setLinksUser(links);
        setVouchersUser(vouchers);
        setLoadingLinks(false);
    }

    const columns: GridColumns = [
        {
            field: "login",
            headerName: "Login",
            width: 150,
        },
        {
            field: "reference_id",
            headerName: "Referência do Usuário",
            width: 340,
            hide:
                userMe.profile.alias === "COMERCIAL" ||
                userMe.profile.alias === "ATENDIMENTO" ||
                userMe.profile.alias === "ATENDIMENTO EXTERNO"
                    ? true
                    : false,
            renderCell: ({ row }: GridRenderCellParams) => {
                const navigateToClarity = () => {
                    const url = `https://clarity.microsoft.com/projects/view/my32o0byv4/dashboard?Variables=user_reference_id%3A${row.reference_id}&date=Last%20356%20days`;
                    window.open(url, "_blank");
                };

                const referenceIdExists = row.reference_id !== "";

                let firstAccessDate;
                if (row.primeiroAcesso) {
                    const [date, time] = row.primeiroAcesso.split(" - ");
                    const [day, month, year] = date.split("/");
                    firstAccessDate = new Date(
                        `${year}-${month}-${day}T${time}`
                    );
                } else {
                    firstAccessDate = new Date("Invalid Date");
                }

                const referenceDate = new Date("2024-11-05T00:00:00");
                const currentTime = new Date();

                const timeDifference = isNaN(firstAccessDate.getTime())
                    ? NaN
                    : Math.abs(
                          currentTime.getTime() - firstAccessDate.getTime()
                      ) /
                      (1000 * 60);

                const shouldShowButton =
                    referenceIdExists &&
                    firstAccessDate.getTime() >= referenceDate.getTime() &&
                    timeDifference >= 120;

                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Text>{row.reference_id}</Text>
                        {shouldShowButton && (
                            <Button
                                onClick={navigateToClarity}
                                style={{
                                    marginLeft: "8px",
                                    padding: "8px",
                                    border: "none",
                                    backgroundColor: "rgb(241, 126, 33)",
                                    borderRadius: "100px",
                                    height: "40px",
                                    fontFamily: "Lato, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    fontSize: "1rem",
                                    textAlign: "center",
                                    color: "rgb(255, 255, 255)",
                                }}
                            >
                                Clarity
                            </Button>
                        )}
                    </div>
                );
            },
        },
        {
            field: "nome",
            headerName: "Nome",
            width: 200,
        },
        {
            field: "level",
            headerName: "Nível",
            width: 100,
        },
        {
            field: "primeiroAcesso",
            headerName: "Primeiro acesso",
            width: 159,
        },
        {
            field: "dataDoResgate",
            headerName: "Data do resgate",
            width: 162,
            hide: hasDataResgate ? false : true,
            renderCell: ({ row }: GridRenderCellParams) => {
                const itens = row.dataDoResgate || [];
                return (
                    <div className="opa">
                        {itens.map((item: string) => (
                            <div key={item}>
                                <Text mb="4px">{item}</Text>
                            </div>
                        ))}
                    </div>
                );
            },
        },
        {
            field: "voucher",
            headerName: "Voucher (s) Resgatado(s)",
            width: 192,
            hide: hasVoucher ? false : true,
            renderCell: ({ row }: GridRenderCellParams) => {
                const itens = row.voucher || [];
                return (
                    <div className="opa">
                        {itens.map((item: string) => (
                            <div key={item}>
                                <Text mb="4px">{item}</Text>
                            </div>
                        ))}
                    </div>
                );
            },
        },
        {
            field: "mobile",
            headerName: "Telefone",
            width: 150,
            hide: hasTelefone ? false : true,
        },
        {
            field: "validator",
            headerName: "Validador",
            width: 200,
            hide: hasValidator ? false : true,
        },
        {
            field: "link",
            headerName: "Link do voucher",
            width: 200,
            hide:
                hasPermission("api/client/private/{id}", "GET") && hasLink
                    ? false
                    : true,
            renderCell: ({ row }: GridRenderCellParams) => {
                const itens = row.link || [];
                return (
                    <div className="opa link-voucher">
                        {itens.length ? (
                            <span onClick={() => getLinks(row.id, row.voucher)}>
                                <img src={imgLink} />
                                Ver Link
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Ação",
            width: 150,
            hide: hasPermission("api/action/users/reset", "POST")
                ? false
                : true,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <ButtonResetUser
                        userId={row.id}
                        actionId={stateHistory.id}
                    />
                );
            },
        },
    ];

    useEffect(() => {
        if (searchUser === null) return;
        setUsers(user.filterLoadUser(searchUser));
    }, [searchUser]);

    if (loading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    return (
        <Container data-testid="table-user">
            <Table
                rows={dados}
                columns={columns}
                nameCSV="csvName"
                header={false}
            />
            <When expr={true}>
                <Pagination
                    total={users.lastPage}
                    currentPage={async (page) => {
                        await fetchData(page);
                    }}
                    page={users.currentPage}
                />
            </When>

            <Modal
                open={state?.linkVoucher}
                handleClose={() => handleClose("linkVoucher")}
            >
                <ModalLinkVoucher
                    links={linksUser}
                    loading={loadingLinks}
                    vouchers={vouchersUser}
                />
            </Modal>
        </Container>
    );
}
