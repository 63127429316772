import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";

export class RemoteUpdateUser {
    constructor(protected readonly httpClient: AxiosHttpClient) {}

    async updateUser(values: any,id: number ): Promise<void> {
        const response = await this.httpClient.request({
            url: values.origin === "USER" ? `users/${id}` : `invites/${id}`,
            method: 'put',
            body: values
        });

        if (response.statusCode !== 200) {
            throw new Error('Erro ao atualizar o usuário');
        }
    }
}
