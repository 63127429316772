import { CheckBox } from "@/presentation/components/CheckBox";
import { Container } from "./styles";

export interface ListVouchersProps {
    foto_capa: string;
    nome: string;
    id: number;
    variation_id: number;
    original_name: string;
    onChange: (id: number) => void;
    checked: boolean;
}

export function ListGroup(props: ListVouchersProps) {
    const { onChange, id, variation_id, original_name, checked } = props;

    return (
        <Container>
            <div className="container-check">
                <CheckBox
                    onChange={() => onChange(id)}
                    checked={checked ? true : false}
                />
            </div>
            <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ color: "#6F3E98" }}>ID Variação:</span>
                    <span style={{ marginLeft: "4px" }}>{variation_id}</span>
                </div>
                
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ color: "#6F3E98" }}>Nome no Estoque:</span>
                    <span style={{ marginLeft: "4px" }}>{original_name}</span>
                </div>
            </div>
        </Container>
    );
}