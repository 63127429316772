import styled from "styled-components";

export const Container = styled.div`
    padding: 32px 46px;
    .container-close {
        position: absolute;
        top: 32px;
        right: 46px;
    }

    .container-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 383px;
        margin: 0 auto;
    }

    .span_importante {
        color: #000;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0%;
        display: block;
        margin-left: 40px;
    }

    .span-erro {
        color: #d50632;
        font-family: Lato;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        margin-bottom: 20px;
    }
`;
