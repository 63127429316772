import React, { createContext, useContext, useState } from "react";
import { FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";
import {
    Authentication,
    AuthenticationParams,
    CurrentUser,
    SmsValidate,
    SmsSend,
} from "@/domain/useCases";
import { LocalSaveAccessToken } from "@/data/useCases/saveAccessToken/localSaveAccessToken";
import { AccountModel, Me } from "@/domain/models";
import { useModal } from "@/main/contexts/modal";
import { toast } from "react-toastify";
import { usePermissions } from "../contexts/permissions";

interface UseAuthenticationProps {
    children: React.ReactNode | React.ReactNode[];
    authentication: Authentication;
    saveAccessToken: LocalSaveAccessToken;
    me: CurrentUser;
    sms: SmsValidate;
    send: SmsSend;
}

export interface UseStateAuthentication {
    onSubmit: (
        values: AuthenticationParams,
        actions?: FormikHelpers<AuthenticationParams>
    ) => void;
    smsValidate(value: string): Promise<void>;
    sendSmsAgain(): Promise<any>;
    sendWhatsapp(): Promise<any>;
    stateMe: any;
    account: AccountModel;
    validateAuthenticator: (code: string) => Promise<void>;
}

const Context = createContext<UseStateAuthentication>(
    {} as UseStateAuthentication
);

//asdsd
export function UseAuthenticationProvider(props: UseAuthenticationProps) {
    const { children, authentication, me, saveAccessToken, sms, send } = props;
    const { handleOpen } = useModal();
    const [stateMe, setStateMe] = useState<Me>({} as Me);
    const [account, setAccount] = useState<AccountModel>({} as AccountModel);

    const history = useHistory();

    const { loadAcessibleRoutes, hasPermission } = usePermissions();

    async function onSubmit(
        values: AuthenticationParams,
        actions?: FormikHelpers<AuthenticationParams>
    ) {
        try {
            const response = await authentication.auth(values);
            const meResponse = await me.user(response.access_token);

            loadAcessibleRoutes(response.access_token);

            await send.send({
                user_id: meResponse.id,
                token: response.access_token,
            });

            setAccount(response);
            setStateMe(meResponse);
            handleOpen("smsValidation");

            actions?.resetForm();
        } catch (error: any) {
            if (error.status === 403) {
                handleOpen("blockedUser");
            } else {
                toast.error("Erro ao processar login.");
            }
            actions?.setSubmitting(false);
        }
    }

    async function smsValidate(value: string) {
        try {
            const response = await sms.validate({
                user_id: stateMe.id,
                token: parseInt(value),
                access_token: account.access_token,
            });

            if (!response.valid) {
                throw new Error("Validação falhou");
            }

            saveAccessToken.save("access_token", account);
            saveAccessToken.save("me", stateMe);

            if (response.google2fa?.qrCode) {
                saveAccessToken.save("google2fa", response.google2fa);
                history.push("/auth/register-authenticator", {
                    qrCodeImage: response.google2fa.qrCode,
                });
            } else {
                history.push("/auth/validate-authenticator");
            }
        } catch (error) {
            console.error("Erro ao validar SMS:", error);
            throw error;
        }
    }

    async function sendSmsAgain() {
        try {
            await send.send({
                user_id: stateMe.id,
                token: account.access_token,
            });
            toast.success("SMS enviado com sucesso!");
        } catch (error: any) {
            console.log(error);
            toast.error("Erro ao enviar SMS!");
            return error;
        }
    }

    async function sendWhatsapp() {
        try {
            await send.send({
                user_id: stateMe.id,
                by: "WHATSAPP",
                token: account.access_token,
            });
            toast.success("WHATSAPP enviado com sucesso!");
        } catch (error: any) {
            console.log(error);
            toast.error("Erro ao enviar WHATSAPP!");
            return error;
        }
    }

    async function validateAuthenticator(code: string): Promise<void> {
        try {
            const response = await fetch("/api/google2fa/validate", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${account.access_token}`,
                },
                body: JSON.stringify({ code }),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(
                    data.error || "Erro desconhecido ao validar 2FA"
                );
            }

            toast.success("Código 2FA validado com sucesso!");
            if (hasPermission("api/action/create", "POST")) {
                history.push("/dashboard");
            } else {
                history.push("/acoes");
            }
        } catch (error) {
            console.error("Erro ao validar o código 2FA:", error);
            toast.error("Erro ao validar o código 2FA!");
        }
    }

    return (
        <Context.Provider
            value={{
                onSubmit,
                smsValidate,
                sendSmsAgain,
                stateMe,
                account,
                sendWhatsapp,
                validateAuthenticator,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useAuthentication() {
    const context = useContext(Context);
    return context;
}
